import { FiChevronDown, FiChevronUp, FiCopy } from "react-icons/fi";
import Popup from "../common/Popup";
import getCountryFlag from "country-flag-icons/unicode";
import toast from "react-hot-toast";
import { toMoneyFormat } from "../common/helpers";
import moment from "moment";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { Spinner, Dropdown } from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";

export const expandedRow = {
  renderer: (row) => {
    if (row.label.parcel?.length > 0) {
      const { length, width, height, length_unit, weight, weight_unit } =
        row.label.parcel || {};

      const parcel_data = (
        <div className="expanded-row-details-item d-flex flex-row justify-content-start align-items-start">
          <div className={"flex-row justify-content-start align-items-start"}>
            <p>Package</p>
            <p>
              L {length}x &nbsp;&nbsp; W {width}x &nbsp;&nbsp; H {height}{" "}
              {length_unit}
            </p>
            <p>
              {weight} {weight_unit}
            </p>
          </div>
        </div>
      );

      return (
        <div className={"expanded-row-details m-0"}>
          <div className={"expanded-row-items m-0"}>
            <div
              className={
                "expanded-row-details-item header d-flex flex-row align-items-center mt-0"
              }
            >
              <div
                className={"flex-row justify-content-start align-items-center"}
              >
                <p>Package</p>
                <p>Dimension</p>
                <p>Weight</p>
              </div>
            </div>
            {parcel_data}
          </div>
        </div>
      );
    } else return <div key={"expanded-id"}></div>;
  },
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandColumnPosition: "left",
  onlyOneExpanding: false,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    if (isAnyExpands) {
      return <FiChevronUp />;
    }
    return <FiChevronDown />;
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
        <div className="icon">
          <FiChevronUp />
        </div>
      );
    }
    return (
      <div className="icon">
        <FiChevronDown />
      </div>
    );
  },
  className: "table-expanded-row",
  parentClassName: "table-expanded-row-container",
};

const labelStatusValue = {
  delivered: "Delivered",
  transit: "Transit",
  pre_transit: "Pre Transit",
  returned: "Returned",
  temporary_failure: "Temporary Failure",
};

export const labelTrackingStatus = (status) => {
  const readableStatus = labelStatusValue[status] || "Pre Transit";
  status = status || "pre_transit";
  return (
    <div
      className={`d-inline-flex flex-row justify-content-center align-items-center status ${status}`}
    >
      {readableStatus}
    </div>
  );
};

export const tableTrackingFormatter = (cell, row) => {
  cell = cell || "pre_transit";
  return (
    <div className=" d-inline-flex flex-column justify-content-start align-items-start">
      {labelTrackingStatus(cell)}
      <div className="d-inline-flex flex-row tracking-number">
        {row.label.tracking_number}
        <Popup.Item
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard
              .writeText(row.label.tracking_number)
              .then(() => {
                toast("Copied");
              });
          }}
          className={"copy-icon"}
        >
          <FiCopy className="m-0" />
        </Popup.Item>
      </div>
    </div>
  );
};

export const tableFromAddressFormatter = (cell, row) => {
  const key = "from-column-value";
  const { name, street1, street2, city, state, country } =
    row.shipment.address_from;
  let address = "";
  if (street1) {
    address += street1 + ",\n";
  }
  if (street2) {
    address += street2 + ",\n";
  }
  const state_str = state ? state : "";
  return (
    <div className={""} key={key}>
      <p className={"customer-name"}>{`${name}`}</p>
      <p
        className="m-0"
        key={key}
      >{`${address}${city}, ${state_str} ${country}`}</p>
    </div>
  );
};

export const tableToAddressFormatter = (cell, row) => {
  const key = "to-column-value";
  const { name, street1, street2, city, state, country } =
    row.shipment.address_to;
  let address = "";
  if (street1) {
    address += street1 + ",\n";
  }
  if (street2) {
    address += street2 + ",\n";
  }
  const state_str = state ? state + "," : "";
  return (
    <div className={""} key={key}>
      <p
        className={"customer-name"}
      >{`${country ? getCountryFlag(country) : ""} ${name}`}</p>
      <p
        className={"customer-address m-0"}
      >{`${address} ${city}, ${state_str} ${country}`}</p>
    </div>
  );
};

export const tableToMoneyFormatter = (cell, row) => {
  if (row.amount_to_be_collected && row.amount_to_be_collected.length > 0) {
    const amount = row.amount_to_be_collected[0].amount;
    const currency = row.amount_to_be_collected[0].currency;
    return <div>{toMoneyFormat(amount, currency)}</div>;
  } else {
    return <div>-</div>;
  }
};

export const formatAddress = (addressObject) => {
  const { name, street1, street2, city, state, country } = addressObject;

  let address = "";
  if (street1) {
    address += street1 + ", ";
  }
  if (street2) {
    address += street2 + ", ";
  }
  return name + ", " + address + city + " " + state + " " + country;
};

const manifestStatusValue = {
  created: "Created",
  scanned: "Picked-Up",
  delivered: "Delivered",
  failed: "Failed",
  partially_delivered: "Partially Delivered",
};

export const formatStatus = (cell, row) => {
  const status = manifestStatusValue[cell];
  return (
    <div
      className={`d-inline-flex flex-row justify-content-center align-items-center status ${cell}`}
    >
      <span className="manifest-status-circle"></span>
      {status}
    </div>
  );
};

export const formatName = (city, createdDate) => {
  return (
    city + " " + moment(new Date(createdDate)).format("MMM D, YYYY hh:MM A")
  );
};

export const formatLinkName = (cell, row) => {
  const city = row.source_address.city;
  const createdDate = row.created_at;
  const url = ROUTES.HOME + ROUTES.MANIFESTS + "/" + row.id;
  return (
    <Link className="manifest-link" to={url}>
      {formatName(city, createdDate)}
    </Link>
  );
};

export const formatDeliveryTime = (tracking_status) => {
  if (tracking_status?.event_type === "delivered") {
    return (
      <p>
        {moment(new Date(tracking_status.utc_happened_at)).format(
          "MMM D, YYYY, h:mm A",
        )}
      </p>
    );
  } else {
    return "-";
  }
};

export const statusDescriptionFormatter = (cell, row) => {
  try {
    let validJson = cell.replace(/'/g, '"');
    const jsonObject = JSON.parse(validJson);
    return <p> {jsonObject.description} </p>;
  } catch (error) {
    return "-";
  }
};

export const ErrorBlock = ({ error }) => {
  return (
    <div className={"error-block"}>
      <FiAlertCircle />
      <p>{error}</p>
    </div>
  );
};

export const DriverComponent = ({
  loading,
  errors,
  drivers,
  selectedDriver,
  setSelectedDriver,
  setter,
}) => {
  if (loading) {
    return <Spinner animation="border" role="status"></Spinner>;
  }

  if (errors) {
    return <div className="alert alert-danger">{errors}</div>;
  }

  if (drivers && drivers.length > 0) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          {selectedDriver ? selectedDriver : "Select A Driver"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {drivers.map((item) => (
            <Dropdown.Item
              key={item.id}
              onClick={() => {
                setSelectedDriver(item.full_name);
                setter("driver")(item.id);
              }}
            >
              {item.full_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return null;
};
